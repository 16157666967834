export default {
  en: {
    title: "Syrian Music Preservation Initiative",
    "nav.home": "Home",
    "nav.bio": "Bio",
    "nav.initiative": "501(c)(3)",
    "nav.logo": "Logo",
    "nav.events": "Events",
    "nav.projects": "Projects",
    "nav.videos": "Music",
    "nav.donate": "Donate",
    "nav.contact": "Contact",
    "nav.menu": "Menu",
    "nav.close": "Close",
    "initiative.donate": "Donate",
    "logo.maker.label": "Logo Calligraphy and Design",
    "logo.maker.name": "Mouneer Al-Shaarani",
    "contact.subscribe": "Subscribe Here",
    "contact.contactus": "Contact Us",
    "contact.firstname": "First Name",
    "contact.lastname": "Last Name",
    "contact.emailaddress": "Email Address",
    "contact.subject": "Subject",
    "contact.message": "Message",
    "contact.subscribe_btn_text": "Subscribe",
    "contact.send_btn_text": "Send",
    "donate.btn_text": "Donate Now",
  },
  ar: {
    title: "مبادرة الحفاظ على الموسيقى السورية",
    "nav.home": "الرئيسية",
    "nav.bio": "نبذة",
    "nav.initiative": "عن الجمعية",
    "nav.logo": "الشعار",
    "nav.events": "أمسيات",
    "nav.projects": "مشاريع",
    "nav.videos": "موسيقى",
    "nav.donate": "للتبرع",
    "nav.contact": "اتصل بنا",
    "nav.menu": "صفحات",
    "nav.close": "أغلق",
    "initiative.donate": "إدعم هنا",
    "logo.maker.label": "مصمم الشعار",
    "logo.maker.name": "منير الشّعراني",
    "contact.subscribe": "اشترك في القائمة البريدية",
    "contact.contactus": "اتّصل بنا",
    "contact.firstname": "الاسم الأوّل",
    "contact.lastname": "اسم العائلة",
    "contact.emailaddress": "البريد الإلكتروني",
    "contact.subject": "الموضوع",
    "contact.message": "الرّسالة",
    "contact.subscribe_btn_text": "اشترك",
    "contact.send_btn_text": "أرسل",
    "donate.btn_text": "إدعم هنا",
  },
}
